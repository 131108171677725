
import { reactive, ref } from '@vue/reactivity'
import Table from '../components/Table.vue'
import Upload from '../components/Upload.vue'
import {
  SELECTTOOLS,
  SELECTALLTOOLSCATEGORY,
  INSERTTOOLS,
  UODATETOOLS,
  DELETETOOLS
} from '../api/api'
import { onMounted, watch } from '@vue/runtime-core'
import { Form } from 'ant-design-vue'

interface toolsFrom {
  id: number
  tools_category_id: number
  tools_name: string
  tools_introduce: string
  tools_url: string
  tools_cover_picture: string
}

export default {
  components: {
    Table,
    Upload
  },
  setup() {
    onMounted(() => {
      table.value.getTableData()
      selectAllToolsCategory()
    })
    const table = ref(null)
    const upload = ref(null)
    // 表格字段
    const columns = ref([
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 50,
        align: 'center'
      },
      {
        title: '工具名称',
        dataIndex: 'tools_name',
        key: 'tools_name',
        width: 300
      },
      {
        title: '工具介绍',
        dataIndex: 'tools_introduce',
        key: 'tools_introduce',
        width: 400
      },
      {
        title: '工具链接',
        dataIndex: 'tools_url',
        width: 400,
        align: 'center'
      },
      {
        title: '封面图片',
        dataIndex: 'tools_cover_picture',
        width: 150,
        align: 'center'
      },
      {
        title: '分类',
        dataIndex: 'tools_category_name',
        width: 150,
        align: 'center'
      },
      {
        title: '创建时间',
        dataIndex: 'create_time_toDate',
        width: 200,
        align: 'center'
      },
      {
        title: '更新时间',
        dataIndex: 'modified_time_toDate',
        width: 200,
        align: 'center'
      },
      {
        title: '操作',
        dataIndex: 'action',
        align: 'center',
        width: 200
      }
    ])
    // 查询表格数据表单
    const selectToolsFrom = reactive({
      tools_name: ''
    })
    // 查询表格数据方法
    const selectTools = (pageData) => {
      return new Promise((resolve, reject) => {
        SELECTTOOLS(Object.assign(pageData, selectToolsFrom))
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
    // 弹窗展示状态
    const toolsModal = ref(false)
    // 弹窗标题
    const toolsModalTitle = ref('')
    // 打开弹窗
    const openModel = (scene, record) => {
      toolsModal.value = true
      if (scene === 1) {
        // 新增
        toolsModalTitle.value = '新增分类'
      } else if (scene === 2) {
        // 编辑
        toolsModalTitle.value = '编辑分类'
        const {
          id,
          tools_category_id,
          tools_name,
          tools_introduce,
          tools_url,
          tools_cover_picture
        } = record
        toolsFrom.id = id
        toolsFrom.tools_category_id = tools_category_id
        toolsFrom.tools_name = tools_name
        toolsFrom.tools_introduce = tools_introduce
        toolsFrom.tools_url = tools_url
        // toolsFrom.tools_cover_picture = tools_cover_picture

        const photoUrl =
          'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/'
        upload.value.fileList[0] = {
          thumbUrl: photoUrl + JSON.parse(tools_cover_picture.toString())[0],
          name: JSON.parse(tools_cover_picture.toString())[0]
        }
        upload.value.returnData = JSON.parse(tools_cover_picture.toString())
      }
    }
    // 所有工具分类信息
    const allToolsCategory = ref([])
    // 查询所有工具分类
    const selectAllToolsCategory = () => {
      SELECTALLTOOLSCATEGORY().then((response) => {
        if (response.code === 0) {
          allToolsCategory.value = response.data
          console.log(allToolsCategory)
        }
      })
    }
    // 添加工具表单
    const toolsFrom = reactive<toolsFrom>({
      id: null,
      tools_category_id: null,
      tools_name: '',
      tools_introduce: '',
      tools_url: '',
      tools_cover_picture: ''
    })
    // 添加工具表单验证规则
    const toolsRules = reactive({
      tools_category_id: [
        {
          required: true,
          message: '请选择工具分类'
        }
      ],
      tools_name: [
        {
          required: true,
          message: '请输入工具名称'
        }
      ],
      tools_introduce: [
        {
          required: true,
          message: '请输入工具介绍'
        }
      ],
      tools_url: [
        {
          required: true,
          message: '请输入工具链接'
        }
      ],
      tools_cover_picture: [
        {
          required: true,
          message: '请上传封面图片'
        }
      ]
    })

    const useForm = Form.useForm
    const { resetFields, validate, validateInfos } = useForm(
      toolsFrom,
      toolsRules
    )
    // 新增工具方法
    const saveTools = async () => {
      await insertPicture()
      // const form = unref(roolsFromRef)

      await validate().then(() => {
        if (toolsFrom.id == null) {
          // 新增工具
          INSERTTOOLS(toolsFrom).then((response) => {
            if (response.code === 0) {
              // 关闭弹窗
              closeModel()
            }
          })
        } else if (toolsFrom.id != null) {
          // 编辑工具
          UODATETOOLS(toolsFrom).then((response) => {
            if (response.code === 0) {
              if (response.code === 0) {
                // 关闭弹窗
                closeModel()
              }
            }
          })
        }
      })
    }

    // 将图片信息插入到提交表单中
    const insertPicture = () => {
      console.log(upload.value.returnData)
      if (JSON.stringify(upload.value.returnData) !== '[]') {
        toolsFrom.tools_cover_picture = JSON.stringify(upload.value.returnData)
      }
    }
    // 关闭弹窗
    const closeModel = () => {
      // 重新获取数据
      table.value.getTableData()
      // 重置表单验证
      resetFields()
      // 关闭弹窗
      toolsModal.value = false
    }
    // 删除数据方法
    const deleteTools = (id) => {
      DELETETOOLS({ id }).then((response) => {
        if (response.code === 0) {
          table.value.getTableData()
        }
      })
    }

    return {
      table,
      upload,
      columns,
      selectToolsFrom,
      selectTools,
      toolsModal,
      toolsModalTitle,
      openModel,
      allToolsCategory,
      toolsFrom,
      saveTools,
      validateInfos,
      closeModel,
      deleteTools
    }
  }
}
